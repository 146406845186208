//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import SimpleActionButton from "@/components/shared/simple-action-button";

export default {
  name: "SelectLocationDialog",

  components: {
    SimpleActionButton,
  },

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapState("common/companyLocations", ["companyLocations"]),
  },

  methods: {
    close() {
      this.$emit("close", false);
    },
    async onLocationSelected(location) {
      if (await this.$root.$confirm('Confirm location', `Proceed with location ${location.name}?`, { color: '#27333f' })) {
        this.$emit("onLocationSelected", location);
      }
    },
  },
};
